@import 'bootstrap';
@import 'fontawesome';
@import 'scroll-reveals';  



// ==============================================================
// **************************************************************
// ==============================================================
// **************************************************************

//--------------------------------------------- 
// GLOBAL
//---------------------------------------------

:root {
    --bs-body-font-weight: 300;
    --default-section-vertical-padding: 3.5rem;
    @include media-breakpoint-up(md) {
        --default-section-vertical-padding: 4rem;
    }
    @include media-breakpoint-up(lg) {
        --default-section-vertical-padding: 4.15rem;
    }
    @include media-breakpoint-up(xl) {
        // (.py-8)
        --default-section-vertical-padding: 4.25rem;
    }
}

// Letter-spacing utility from Adobe XD (uses 'milli-ems' units)...
@mixin letter-spacing($spacing) {
    letter-spacing: calc(($spacing / 1000) * 1em);
}

// The navbar toggler icon's height is 40px, so adding that to the block padding of the navbar to
// ensure the video isn't cut off
.first-section-offset {
    //margin-top: calc((#{$navbar-padding-y} * 2) + 40px);
    margin-top: calc(#{$navbar-padding-y} + 40px);
    //margin: 0 0;
}

* {
    @include letter-spacing(-18.9);
}

a:not([class]) {
    transition-property: opacity;
    transition-duration: 300ms;
    &:hover, &:focus, &:active {
        text-decoration: underline;
    }
    &:hover {
        opacity: 0.7;
    }
}

// ==============================================================
// **************************************************************
// ==============================================================
// **************************************************************


//---------------------------------------------
// 404 page
//---------------------------------------------
#error-404 {
    min-height: 75dvh;
    min-height: 75vh;
}

// ==============================================================
// Header
// ==============================================================
$header-height: 70px;
$admin-nav-height: 38px;

#header {
    @include media-breakpoint-down (md) {
        background: transparent;
        border-bottom: none;
    }

    z-index: 140;
    &.scroll-fix {
        box-shadow: 0 0 5px rgba(0,0,0,0.25);
    }
}

// ==============================================================
// Navigation
// ==============================================================
.navbar {
    //background: $gray-700;
    @include media-breakpoint-down (lg) {
        background: transparent;
    }
    .navbar-brand {
        padding-top: 0;
        padding-bottom: 0;

        img {
            max-height: 33px;
        }
    }
}
.navbar.navbar-secondary {
    //height: 70px;
    margin-right: 15px;
    .nav-toggler {
        color: $gray-600;
    }
}

#main-navigation {
    // DESKTOP
    ul {
        flex-direction: row;
        li {
            a {
                >span {
                    &::after {
                        height: 2px;
                        // background: $white;
                        background-color: $gray-400;
                    }
                }
                &.about,
                &.technologies,
                &.contact {
                    font-weight: $font-weight-semibold;
                }
            }
            &:nth-of-type(n+4) {
                a {
                    >span, >span[class] {
                        &::after {
                            height: 2px;
                            // background: $white;
                            background-color: $gray-400;
                        }
                    }
                }
            }
        }
    }

    // MOBILE
    @include media-breakpoint-down(md) {
        position: fixed;
        bottom: 0;
        top: 0;
        height: 100%;
        z-index: 100;
        background-color: #d8d8d8;
        right: -100%;
        transition: right 0.3s ease;
        padding: 3rem;
        text-align: center;

        .nav-toggler {
            color: $gray-600;
        }

        ul {
            flex-direction: column;
            li {
                a {
                    color: $gray-600;
                    //padding: 0.75rem 0;
                    line-height: 1.3;
                    @include font-size(1.25rem);
                    >span {
                        &::after {
                            background: $gray-600;
                        }
                    }
                    &.about,
                    &.technologies,
                    &.contact {
                        > span {
                            &::after {
                                height: 4px;
                            }
                        }
                        font-weight: $font-weight-bold;
                        @include font-size(1.95rem);
                    }

                    &:not(.technologies) {
                        >span.yellow {
                            &::after {
                                height: 2px;
                                background-color: $gray-600;
                            }
                        }
                    }
                }
            }
        }
        &.active, &.show {
            right: 0;
            // width: auto;
            // max-width: 400px;
        }
    }

    //padding-top: 140px;
    /*@include media-breakpoint-up (lg) {
        min-width: 400px;
    }*/


    /*&.active {
        right: 0;
        width: 100%;
        max-width: 100%;
        @include media-breakpoint-up(md) {

        }
    }*/

    ul {
        li {
            a {
                >span {
                    position: relative;

                    &::after {
                        position: absolute;
                        content: " ";
                        bottom: -4px;
                        left: 0;
                        width: 4px;

                        transition: width 0.3s ease 0.15s;
                        opacity: 0;
                    }

                    &.orange {
                        &::after {
                            background-color: $orange;
                        }
                    }

                    &.yellow {
                        &::after {
                            background-color: $yellow;
                        }
                    }

                    &.blue {
                        &::after {
                            background-color: $blue;
                        }
                    }

                    &.green {
                        &::after {
                            background-color: $green;
                        }
                    }
                }

                &.active,
                &:hover {
                    >span {
                        &::after {
                            width: 100%;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

}

.navbar .nav-toggler {

    //color: $white;
    padding-top: 0;
    padding-bottom: 0;
    border: none;
    font-size: 1rem;
    font-weight: $font-weight-normal;
    border-color: transparent;
    border-radius: 0;
    outline: none !important;
    position: relative;
    //z-index: 101;
    background: transparent;

   // &.active {
        //color: $gray-600;
   // }

    .toggle-icon {
        display: block;
        width: 25px;
        height: 21px;
        position: relative;
        top: 50%;

        .bar {
            display: block;
            width: 100%;
            height: 2px;
            background: $white;
            margin-bottom: 7px;
            transition: all 0.3s ease;
            position: relative;

            &:nth-of-type(3) {
                margin-bottom: 0;
            }
        }
    }

    &.active {
        .toggle-icon {
            .bar {
                &:nth-child(1) {
                    transform: rotate(45deg);
                    top: 9px;
                }

                &:nth-child(2) {
                    transform: rotate(-45deg);
                    top: 0px;
                }

                &:nth-child(3) {
                    opacity: 0;
                }
            }
        }
    }
}

body {
    &.active-nav {
        #main-navigation {
            @include media-breakpoint-down(md) {
                top: 0;
                animation: fadeInNavigation 0.6s ease forwards;
            }

        }
    }
    &.windows {
        * {
            font-weight: 400;
        }
        
    }
}


// ==============================================================
// Homepage Video
// ==============================================================
#hp-video {

    // IMPORTANT: These are used to update:
    // 1. the 'overshoot r-side width of the hp video;
    // 2. adjust the abs posned play icon (right and bot)
    --video-extra-width-adjustment: 0rem;
    // @include media-breakpoint-up(xxl) {
    //     --video-extra-width-adjustment: 20px
    // }
    // @include media-breakpoint-up(xxxl) {
    //     --video-extra-width-adjustment: 50px;
    // }

    // The navbar toggler icon's height is 40px, so adding that to the block padding of the navbar to
    // ensure the video isn't cut off
    @extend .first-section-offset;

    @include media-breakpoint-up(lg) {
        // margin-top: 0;
        // min-height: 100dvh;
        // min-height: 100vh;
        // grid-template-columns: repeat(4, 1fr);
        // grid-template-areas: '. video video video'
        //                     'text video video video'
        //                     '. video video video';
        // align-items: center;
    }
    .play-icon {
        --play-icon-size: 50px;
        --play-icon-posn: 20px;
        position: absolute;
        top: calc(var(--default-section-vertical-padding) + var(--play-icon-posn));
        // right: calc(#{$container-padding-x} + var(--play-icon-posn));
        right: calc(#{$container-padding-x} + var(--play-icon-posn));
        bottom: var(--play-icon-posn);
        width: var(--play-icon-size);
        height: var(--play-icon-size);
        z-index: 21;
        opacity: 0.65;
        transition: opacity 0.1s ease;
        &:hover {
            opacity: 1;
        }
        img {            
            width: var(--play-icon-size);
            height: var(--play-icon-size);
        }
        
        @include media-breakpoint-up(lg) {
            --play-icon-size: 50px;

            // TODO: Fix up position lg & up
            top: unset;
            bottom: var(--play-icon-posn);
            right: calc((-1 * var(--video-extra-width-adjustment)) + var(--play-icon-posn));
        }
    }
    .video-text-content {
        order: 1;
        @include media-breakpoint-up(lg) {
            z-index: 1;
            order: 0;
            grid-area: text / text / 2 / 3;
            // ^^ grid-area: grid-row-start / grid-column-start / grid-row-end / grid-column-end
        }
        .video-title {
            font-size: 2.5rem;
            //font-weight: 100;
            @include media-breakpoint-up(lg) {
                //color: $gray-400 !important;
                font-size: 4.25rem;
            }
        }
        .video-subtitle {
            font-size: 1.5rem;
            //font-weight: 100;
            @include media-breakpoint-up(lg) {
                //color: $gray-400 !important;
            }
        }
        .video-link {
            font-size: 1.25rem;
            font-weight: 400;
        }
    }
    .video-wrapper {
        order: 0;
        @include media-breakpoint-up(lg) {
            aspect-ratio: 16/9;
        }
        video {
            // max-height: 50dvh;
            // max-height: 50vh;
            max-width: 100vw;
            @include media-breakpoint-up(lg) {
                max-width: unset;
            }

            //********************************* 
            // IMPORTANT: These are MAGIC#: based on eyeball of design
            // as the video reaches outside of the R side of the container on iMacs
            // ***I've added an xxxl breakpoint to variables to account for this*** 
            @include media-breakpoint-up(xl) {
                width: calc(100% + var(--video-extra-width-adjustment)) !important;
            }
            //********************************* 
        }
        @include media-breakpoint-up(lg) {
            grid-area: video;
            order: 1;
        }
    }
}


// ==============================================================
// Solutions - Homepage
// ==============================================================
.solutions {
    h2 {
        margin-bottom: 2rem;
    }
    & > .d-grid {
        row-gap: 2.5rem;
    }
    .solutions-list > .d-grid {
        --gap: 1rem;
        grid-gap: var(--gap);
        @include media-breakpoint-up(xl) {
            --gap: 4rem;
            --min: 300px;
            --max: 315px;
            /* min() with 100% prevents overflow
            in extra narrow spaces */
            grid-template-columns: repeat(auto-fit, minmax(Min(100%, var(--min)), var(--max)));
        }
        @include media-breakpoint-up(xxxl) {
            --max: 350px;
            --gap: 8.125rem;
        }
    }
    .solution-icon {
        margin-bottom: 1.25rem;
        width: 2.5rem;
    }
    .solution {
        h4 {
            transition-property: color;
            transition-duration: 300ms;
        }
        .solution-icon {
            transition-property: transform;
            transition-duration: 300ms;
        }
        &:hover, &:focus {
            a {
                color: $blue;
            }
            .solution-icon {
                transform: translateY(-4px);
            }
        }
        p {
            @include media-breakpoint-up(xl) {
                font-size: 1.125rem;
            }
        }
    }
}

//---------------------------------------------
// LEADERSHIP page
//---------------------------------------------
.team-member {
    --minTeamMemHeight: 300px;
    row-gap: 1.25rem;
    .team-member-img-link {
        min-height: var(--minTeamMemHeight);
        height: 300px;
        overflow: hidden;

        &:hover {
            img {
                transform: scale(1.05);
            }
        }
        img {
            height: 100%;
            min-height: var(--minTeamMemHeight);
            width: 100%;
            object-fit: cover;

            transition: transform;
            transition-duration: 300ms;
        }
    }
}

//---------------------------------------------
// VALUES wrapper section
//---------------------------------------------
.values-wrapper {
    @include media-breakpoint-up(xl) {
        gap: 3rem;
    }
    @include media-breakpoint-up(xl) {
        column-gap: 6rem;
        row-gap: 4rem;
        grid-template-areas: 
            'header image'
            'values image'
            'values image'
            'values footer';
        .values-header-copy {
            grid-area: header;
        }
        .values-footer-copy {
            grid-area: footer;
            border-left: 1px solid transparentize($indigo, $amount: .5);
        }
        .values-image {
            grid-area: image;
        }
        .values {
            grid-area: values;
        }
    }
    .value {
        @include media-breakpoint-up(xl) {
            padding-inline: 1.75rem;
        }
        @include media-breakpoint-up(xl) {
            padding-inline: 2.5rem; 
        }
    }
}

// ==============================================================
// Footer
// ==============================================================
#footer {
    background-color: $indigo;
    a {
        &:hover, &:focus, &:active {
            text-decoration: underline !important;
        }
    }
}


// ==============================================================
// Custom Styles
// ==============================================================

section {
    &:first-of-type {
        @extend .first-section-offset;
    }
    // NOTE: This is a 'global fallback' padding on sections if custom isn't defined
    padding-block: var(--default-section-vertical-padding);
}

h1.display-heading,
.h1.display-heading {
    @include font-size(3.25rem);
}

h2.display-heading,
.h2.display-heading {
    @include font-size(2.75rem);
}

h3.display-heading,
.h3.display-heading {
    @include font-size(2.25rem);
}

h4.display-heading,
.h4.display-heading {
    @include font-size(1.75rem);
}

.display-heading {
    font-weight: 100;
}

h1.dash-heading,
.h1.dash-heading {
    //@include font-size(1.6rem);
}

h2.dash-heading,
.h2.dash-heading {
    //@include font-size(1.3rem);
}

h3.dash-heading,
.h3.dash-heading {
    //@include font-size(0.9rem);
}

.dash-heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &::after {
        content: " ";
        height: 1px;
        background: inherit;
        margin-left: 1rem;
        // ANIMATION to get bigger/smaller
        transition: flex 0.3s ease;
        //flex: 0;
        flex: 1;
        max-width: 125px;
    }

    &.text-blue, &.text-primary, &.blue {
        color: $blue;

        &::after {
            background: $blue;
        }
    }

    &.orange {
        color: $orange;

        &::after {
            background: $orange;
        }
    }

    &.yellow {
        color: $yellow;

        &::after {
            background: $yellow;
        }
    }
    &.indigo, &.text-indigo {
        color: $indigo;
        &::after {
            background: $indigo;
        }
    }
    &.green {
        color: $green;
 
        &::after {
            background: $green;
        }
    }
}

// Overwriting the .btn-outline-primary and .btn-outline-blue
// Tried to modify the mixin but dont want to update src files
.btn-outline-primary, .btn-outline-blue {
    border-color: $primary !important;
    @include button-outline-variant(black, white, $primary, $primary, white);
}

// .btn-arrow {
//     // @extend .btn-outline-primary;
//     // @extend .btn;
//     // @extend .text-g;
//     &::after {
//         font-family: 'Font Awesome 6 Free';
//         content: '\f054';
//         position: absolute;
//         opacity: 0;  
//         top: 14px;
//         right: -20px;
//         transition: 0.5s;
//     }
//     &:hover {
//         &::after {
//             color: white;
//             filter: invert(1);
//         }
//     }
// }

// ==============================================================
// Introduction
// ==============================================================
.introduction {
    .content-grid {
        gap: 2rem;
        @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(2, 1fr);
            column-gap: 6rem;
        }
    }
    // .header-copy {

    // }
    // .header-img--img {

    // }
    h2 {
        margin-bottom: 3rem;
    }
}


// ==============================================================
// Map
// ==============================================================
#hand-swipe-icon {
    opacity: 0;
    transition: all .5s ease-in-out;
    transform-origin: 52% 62%;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    z-index: 1;
    &.reveal {
        opacity: 1;
        // see _scroll-reveals for keyframes definition
        animation-name: swipe-hand;
        animation-duration: 3.5s;
        // transform-origin: bottom center;
        backface-visibility: hidden; 
        perspective: 1000px;
    }
}

.map {
    overflow-y: hidden;
    max-width: 1902px;
    margin: 0 auto;
    cursor: grab;
    &::-webkit-scrollbar {
        height: 0;
    }

    scrollbar-width: none;
    overflow-x: -moz-scrollbars-none;
    -ms-overflow-style: none;

    .map-outer {
        //width: 1660px;
        width: 1902px;
        position: relative;
        cursor: grab;
        &:focus, &:active {
            cursor: grabbing;
        }
    }

    .map-inner {

        //height: 611px;
        //width: 1660px;
        height: 700px;
        width: 1902px;

        .location {
            text-align: left;
            position: absolute;
            font-size: 14px;
            padding-right: 10px;
            padding-left: 10px;
            user-select: text;
            cursor: text;
            &::after {
                content: " ";
                width: 100px;
                left: 100%;
                height: 1px;
                background: $white;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }

            h5 {
                font-size: 14px;
                font-weight: 500;
            }

            ul {
                margin-bottom: 0;

                li {
                    line-height: 1.5;
                }
            }

            &.usa {
                left: 40px;
                top: 127px;

                &::after {
                    width: 122px;
                }
            }

            &.mexico {
                left: 166px;
                top: 233px;

                &::after {
                    width: 45px;
                }
            }

            &.germany {
                left: 748px;
                top: 111px;

                &::after {
                    width: 65px;
                }
            }

            &.japan {
                left: 1646px;
                top: 166px;

                &::after {
                    left: -85px;
                    width: 85px;
                }
            }

            &.china {
                left: 1535px;
                top: 256px;

                &::after {
                    left: -75px;
                    width: 75px;
                }
            }
        }

        //background-image: url('/assets/img/map.png');
        background-image: url('/assets/img/map-2023.png');
        background-repeat: no-repeat;
        background-position: center top;
        background-size: auto 100%;

        img {
            height: 100%;
            //left: 50%;
            //transform: translateX(-50%);
            //position: relative;

            @media (max-width: 1700px) {
                //margin: 0 15rem;
            }

        }
    }
}

.map-types {
    .type {
        max-width: 220px;
    }
}

.location-item {
    position: relative;
    display: block;

    .location-detail {
        display: none;
        min-width: 325px;
        position: absolute;
        //background: $gray-800;
        background: $gray-200;
        @extend .shadow-sm;
        left: 0;
        top: 100%;
        z-index: 100;
    }

    &:hover {
        .location-detail {
            display: block;
        }
    }
    a {
        &:hover {
            color: $blue;
        }
    }
}

.location.china,
.location.japan {
    .location-item {
        .location-detail {
            right: 0;
            left: auto;
        }
    }
}

// ==============================================================
// Pagination
// ==============================================================
.pagination {
    list-style: none;
    display: flex;
    margin: 0 0;
    padding: 0 0;
    align-items: center;

    li {
        padding: 0 0.75rem;
        border-right: 1px solid $white;
        line-height: 1.3;

        &:nth-child(1) {
            padding-left: 0;
        }

        a {
            color: $black;
            text-decoration: none;
        }

        &.active {
            font-weight: 400;
        }
    }
}
.slick-dots {
    position: relative !important;
    
    li {
        width: 16px !important;
        height: 16px !important;
        border-radius: 100rem;
        background: transparent;

        button {
            border: 2px solid $indigo;
            color: $indigo;
            position: relative;
            width: 16px !important;
            height: 16px !important;
            border-radius: 100rem;
            padding: 0 !important;
            margin: 0 !important;
            overflow: hidden;

            &::after {
                content: " ";
                background: $indigo;
                position: absolute;
                top: 0px;
                left: 0px;
                width: 16px;
                height: 16px;
                //border-radius: 100rem;
                transition: top 0.3s ease;
                top: 100%;
            }

            &::before {
                line-height: 16px !important;
                content: " " !important;
                width: 16px !important;
                height: 16px !important;
                border-radius: 100rem;
                border: 2px solid $indigo;
                opacity: 1 !important;
            }
        }

        &.slick-active {
            button {
                &::after {
                    top: 0;
                }
            }
        }
    }
}
.slick-arrow {
    &::before {
        content: none !important;
    }
    &.slick-prev {
        left: -30px;
        @include media-breakpoint-up(xl) {
            left: -37.5px;
        }
        svg {
            transform: rotate(180deg);
        }
    }
    &.slick-next {
        right: -30px;
        @include media-breakpoint-up(xl) {
            right: -37.5px;
        }
    }
}

.dashed-title {
    margin-bottom: 2.25rem; // 36px
}
// ==============================================================
// Slideshows
// ==============================================================
.slideshow-title {
    @extend .dashed-title;
}

.slideshow.slick-initialized.slick-slider.slick-dotted, 
.page-slideshow, 
.content-image-container {
    // margin-bottom: 5rem !important;
    position: relative;
    margin-bottom: 0 !important;

    &:not(.homepage) {
        .slide-content {
            // Ensures there's space between the dots nav
            // and the last bit of copy in the slide text area
            a.btn {
                margin-top: 0;
                height: min-content;
            }
            .slide-dots-holder {
                margin-top: auto;

                .slick-dots {
                    bottom: 0;
                }
            }

        }
        .slick-dots {
            @include media-breakpoint-up(lg) {
                width: fit-content;
    
                // NOTE: Slide content is using .p-7 = 5rem
                // Slick slider has 30px margin on bottom of container
                // bottom: calc(5rem + 30px);
                // left: 5rem;
                
                // Left aligns the first slide nav dot since there's margins
                // on both sides of each by default
                li:first-of-type {
                    margin-left: 0;
                }
            }
        }
    }

    .slide {
        height: fit-content;
        @include media-breakpoint-up(md) {
        //     min-height: 200px; 
            height: auto;
        }
        .slide-content {
            row-gap: 1rem;
            .copy {
                display: flex;
                flex-direction: column;
            }
        }
        .slide-image {
            min-height: 200px;
            img {
                aspect-ratio: 1.6;
            }
        }
        a.btn {
            margin-top: auto;
            display: table;
            width: fit-content;
        }
    }
    
    .slick-list, .slick-track {
        display: flex;
    }

    .slide-block {
        position: relative;
    }
}

.line-col {
    position: relative;
    &:not(:last-of-type) {
        &::after {
            content: '';
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            top: 0;
            position: absolute;
            width: 2px;
            height: 100%;
            background: $blue;
            z-index: 1;
        }
    }
    &::before {
        content: '';
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        top: 0;
        border-radius: 50px;
        border: 2px solid $blue;
        position: absolute;
        width: 12px;
        height: 12px;
        background: $white;
        z-index: 2;
    }
    > .last {
        &::before {
            content: '';
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            top: auto;
            border-radius: 50px;
            border: 2px solid $blue;
            position: absolute;
            width: 12px;
            height: 12px;
            background: $white;
            z-index: 2;
        }
    }
}


// ==============================================================
// Content + Image Block
// ==============================================================
// .content-image, .team-member-bio {
//     position: relative;
//     .row {
//         position: relative;
//     }
//     .content-holder {
//         @include media-breakpoint-up(xl) {
//            //min-height: 95vh;
//         }
//         @include media-breakpoint-up(xxl) {
//             &.order-lg-0 {
//                 //padding-right: 16% !important;
//                 //padding-left: 5% !important;
//             }
//             &.order-lg-1 {
//                 //padding-left: 12% !important;
//                 //padding-right: 5% !important;
//             }
//          }
//     }
// }

.content-image-image {
    z-index: 1;
    @include media-breakpoint-up(lg) {
      position: relative !important;
      //max-height: 65%;
      overflow: hidden;
      top: unset !important;
      transform: none !important;
      left: unset !important;
      //padding: 0;

      &.order-lg-1 {
          left: auto;
          right: 0;
      }
  }
}

.content-image-image {
  z-index: 1;
    @include media-breakpoint-up(lg) {
        position: absolute;
        //max-height: 65%;
        overflow: hidden;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        padding: 0;

        &.order-lg-1 {
            left: auto;
            right: 0;
        }
    }

    @include media-breakpoint-down(md) {
        // margin-top: -3rem;
        // padding-left: 3rem;
        // padding-right: 1rem;

        &.order-lg-1 {
            padding-right: 3rem;
            padding-left: 1rem;
        }
    }

    img {
        //height: 100%;
        //width: 100%;
        //object-fit: cover;
    }
}

// .team-member-bio {
//   .content-image-image {
//     @include media-breakpoint-up(lg) {
//       max-height: 100% !important;
//     }
//   }
// }


// ==============================================================
// Downloads
// ==============================================================
.downloads {
    .download-col {
        display: grid;
        grid-template-columns: 1;
        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(2, 1fr);
            column-gap: 2.5rem;
        }
        @include media-breakpoint-up(xxl) {
            grid-template-columns: repeat(3, 1fr);
            column-gap: 5rem;
        }
        @include media-breakpoint-up(xxl) {
            column-gap: 8rem;
        }
    }
}
.toggle-block {
    border-bottom: 1px solid $primary;
    &.active {
        h4 {
            color: $primary;
        }
    }
    &:hover, &:focus, &:active {
        h4 {
            color: $primary;
        }
    }
    h4 {
        cursor: pointer;
        transition-property: opacity, color;
        transition-duration: 300ms;
        transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
        //font-weight: 200;

        .toggle-icon {
            //font-weight: 200;
            padding-left: 15px;
        }
    }

    .toggle-description {
        //font-weight: 400;
    }

    &.active {
        h4 {
            //font-weight: 400;
        }
    }

    .toggle-content {
        display: none;
    }
}

.download-list {
    li {
        .download-link {
            color: $black;
            transition-property: color;
            transition-duration: 300ms;
            position: relative;
            padding-left: 15px;
            &:hover, &:active, &:focus {
                color: $indigo;
            }
        }

        &::after {
            content: "-";
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}

//---------------------------------------------
// News Content
//---------------------------------------------
.news-entry {
    a {
        cursor: pointer;
        transition-property: opacity, color;
        transition-duration: 300ms;
        transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
        &:hover, &:focus, &:active{
            color: $primary;
        }
    }
}

//---------------------------------------------
// Text Columns
//---------------------------------------------
.text-columns {
    --total-column-count: attr(data-total-columns);
    .columns-wrapper {
        gap: 1.5rem;
        @include media-breakpoint-up(md) {
            column-gap: 2rem;
            grid-template-columns: repeat(2, 1fr);
        }
        @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(3, 1fr);
        }
        @include media-breakpoint-up(xl) {
            --minColWidth: 12.5rem; // 200px
            grid-template-columns: repeat(auto-fit, minmax(min(100%, var(--minColWidth)), 1fr));
        }
    }
}
