.scroll-reveal {
    opacity: 0;
    -webkit-transition: opacity 1s ease, -webkit-transform 1s ease;
    transition: opacity 1s ease, -webkit-transform 1s ease;
    -o-transition: opacity 1s ease, transform 1s ease;
    transition: opacity 1s ease, transform 1s ease;
    transition: opacity 1s ease, transform 1s ease, -webkit-transform 1s ease
}

.scroll-reveal.visible {
    opacity: 1
}

@media (min-width: 992px) {
    .scroll-reveal.delay-1,.scroll-reveal.delay-1.slide-dash::after {
        -webkit-transition-delay:0s;
        -o-transition-delay: 0s;
        transition-delay: 0s
    }

    .scroll-reveal.delay-2,.scroll-reveal.delay-2.slide-dash::after {
        -webkit-transition-delay: 0.3s;
        -o-transition-delay: 0.3s;
        transition-delay: 0.3s
    }

    .scroll-reveal.delay-3,.scroll-reveal.delay-3.slide-dash::after {
        -webkit-transition-delay: 0.6s;
        -o-transition-delay: 0.6s;
        transition-delay: 0.6s
    }

    .scroll-reveal.delay-4,.scroll-reveal.delay-4.slide-dash::after {
        -webkit-transition-delay: 0.9s;
        -o-transition-delay: 0.9s;
        transition-delay: 0.9s
    }

    .scroll-reveal.delay-5,.scroll-reveal.delay-5.slide-dash::after {
        -webkit-transition-delay: 1.2s;
        -o-transition-delay: 1.2s;
        transition-delay: 1.2s
    }

    .scroll-reveal.delay-6,.scroll-reveal.delay-6.slide-dash::after {
        -webkit-transition-delay: 1.5s;
        -o-transition-delay: 1.5s;
        transition-delay: 1.5s
    }
}

.scroll-reveal.slide-dash::after {
    -webkit-box-flex: 0;
    -webkit-flex: 0;
    -ms-flex: 0;
    flex: 0;
    -webkit-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
    transition: all 0.8s ease
}

.scroll-reveal.slide-dash.visible::after {
    -webkit-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.scroll-reveal.slide-right {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%)
}

@media (min-width: 992px) {
    .scroll-reveal.slide-right.content-image-image {
        -webkit-transform:translateX(-100%) translateY(-50%);
        -ms-transform: translateX(-100%) translateY(-50%);
        transform: translateX(-100%) translateY(-50%)
    }
}

.scroll-reveal.slide-right.visible {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0)
}

@media (min-width: 992px) {
    .scroll-reveal.slide-right.visible.content-image-image {
        -webkit-transform:translateX(0%) translateY(-50%);
        -ms-transform: translateX(0%) translateY(-50%);
        transform: translateX(0%) translateY(-50%)
    }
}

.scroll-reveal.slide-left {
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%)
}

@media (min-width: 992px) {
    .scroll-reveal.slide-left.content-image-image {
        -webkit-transform:translateX(100%) translateY(-50%);
        -ms-transform: translateX(100%) translateY(-50%);
        transform: translateX(100%) translateY(-50%)
    }
}

.scroll-reveal.slide-left.visible {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0)
}

@media (min-width: 992px) {
    .scroll-reveal.slide-left.visible.content-image-image {
        -webkit-transform:translateX(0%) translateY(-50%);
        -ms-transform: translateX(0%) translateY(-50%);
        transform: translateX(0%) translateY(-50%)
    }
}

.scroll-reveal.slide-up {
    -webkit-transform: translateY(15%);
    -ms-transform: translateY(15%);
    transform: translateY(15%)
}

.scroll-reveal.slide-up.visible {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0)
}

.scroll-reveal.slide-down {
    -webkit-transform: translateY(-15%);
    -ms-transform: translateY(-15%);
    transform: translateY(-15%)
}

.scroll-reveal.slide-down.visible {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0)
}

@-webkit-keyframes slideDash {
    from {
        -webkit-box-flex: 0;
        -webkit-flex: 0;
        flex: 0
    }

    to {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        flex: 1
    }
}

@keyframes slideDash {
    from {
        -webkit-box-flex: 0;
        -webkit-flex: 0;
        -ms-flex: 0;
        flex: 0
    }

    to {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1
    }
}

//---------------------------------------------
// For the MAP #hand-swipe-icon
//---------------------------------------------

  @keyframes swipe-hand {
    0% {
        opacity: 0;
    }
    10% { 
        opacity: 1;
    }
    22% {
        transform: translate(-20px) rotate(-15deg);
    }
    36% { 
        transform: translate(20px) rotate(30deg); 
    }
    58% { 
        transform: translate(0px) rotate(0); 
    }
    73% {
        transform: translate(-20px) rotate(-15deg);
    }
    88% { 
        transform: translate(20px) rotate(30deg); 
    }
    90% {
        opacity: 1;
    }
    100% { 
        opacity: 0;
        transform: translate(0px) rotate(0); 
    }
  }